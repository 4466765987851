import GatsbyImage from "gatsby-image";
import { get } from "lodash";
import React, { useState } from "react";
import Footer from "../components/Layout/Footer";
import Navbar from "../components/Layout/Navbar";
import TopBar from "../components/Layout/TopBar";
import Seo from "../components/seo";
import SEO from "../components/seo";

import useIntersectionObserver from "../loaders/useIntersectionObserver";

import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

import FilterListing from "../components/Layout/FilterListing";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import Navigation from "../components/Layout/Navigation";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
export default function FilterPag({ data, pageContext }) {
  return (
    <>
    {/* <SEO title="Newest dogs" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | Newest dogs"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <AnimateSharedLayout>
        {" "}
        <motion.div layout>
          <AnimatePresence initial={false}>
            <FilterListing
              data={data.stories}
              title={"Newest Dogs"}
              type={"year"}
            />
          </AnimatePresence>
        </motion.div>
      </AnimateSharedLayout>
      <Footer></Footer>
    </>
  );
}
export const query = graphql`
  {
    stories: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/src/storys/" }
        frontmatter: { year: { gt: 2019 } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          subtitle
          subtitle2
          tags
          slug
          year
          thumb {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }
  }
`;
